var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "client",
      "dataset-name": "time-tracker-payroll-calendar",
      "hide-search-button": true,
      "show-labels": false,
      "compact": false,
      "loaded": _vm.filteringPanel.loaded,
      "hide-custom-filters": true,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "search": _vm.onFilteringPanelSearch,
      "reset": _vm.onFilteringPanelReset,
      "loaded": _vm.onFilteringPanelLoad
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('b-table', {
    attrs: {
      "striped": "",
      "selectable": "",
      "fields": _vm.calendar.columns,
      "items": _vm.calendar.data
    },
    on: {
      "row-clicked": _vm.onRowClicked
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }