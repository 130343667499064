<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="client"
              dataset-name="time-tracker-payroll-calendar"
              :hide-search-button="true"
              :show-labels="false"
              :compact="false"
              :loaded="filteringPanel.loaded"
              :hide-custom-filters="true"
              :filters="filteringPanel.filters"
              @change="onFilteringPanelChange"
              @search="onFilteringPanelSearch"
              @reset="onFilteringPanelReset"
              @loaded="onFilteringPanelLoad"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-table
              striped
              selectable
              :fields="calendar.columns"
              :items="calendar.data"
              @row-clicked="onRowClicked"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";

import FilteringPanel from "@/components/FilteringPanel";

import { mapState } from "vuex";

export default {
  components: {
    FilteringPanel
  },
  data: function() {
    return {
      isLoading: false,
      rawData: {},
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "This year full",
            title: "Period",
            tooltip: "Payroll period",
            name: "period",
            options: []
          }
        ]
      },

      calendar: {
        columns: [
          "Beginning of period",
          "End of period",
          "Payroll run date",
          "Pay date"
        ],
        data: []
      }
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  mounted() {
    //  this.createCalendar()
  },
  methods: {
    onRowClicked(e) {
      if (this.profile.data.role === "H2") {
        this.$router.push({
          name: "All users payroll",
          params: {
            startDate: e.startDate,
            endDate: e.endDate,
            run: true
          }
        });
      } else {
        this.$router.push({
          name: "My payroll",
          params: {
            startDate: e.startDate,
            endDate: e.endDate,
            run: true
          }
        });
      }
    },
    onFilteringPanelReset() {},
    onFilteringPanelChange(e) {
      this.filteringPanel.selected = e;

      this.createCalendar();
    },
    onFilteringPanelSearch(e) {
      this.filteringPanel.selected = e;

      this.createCalendar();
    },
    onFilteringPanelLoad() {
      this.filteringPanel.selected = this.$refs.filteringPanel.selected;

      this.createCalendar();
    },
    createCalendar: function() {
      let start = this.filteringPanel.selected["period"].startDate;
      let end = this.filteringPanel.selected["period"].endDate;

      //let dates = this.$helpers.getBiWeeklyDatesArray(start, end, false);
      let dates = this.$helpers.getBiWeeklyDatesArray(
        process.env.VUE_APP_BI_WEEKLY_START,
        end,
        false
      );

      console.log(
        "process.env.VUE_APP_BI_WEEKLY_START",
        process.env.VUE_APP_BI_WEEKLY_START
      );
      dates = dates.filter(p => moment(p.endDate).isSameOrAfter(moment(start)));
      console.log("dates:", dates);

      this.calendar.data = [];
      let selected = false;
      dates.forEach(p => {
        let payRollRunDate = moment(p.endDate)
          .clone()
          .add(2, "week")
          .isoWeekday(3);

        let payDate = moment(payRollRunDate)
          .clone()
          .isoWeekday(5);

        let row = {
          "Beginning of period": moment(p.startDate).format(
            "dddd, MMMM DD, YYYY"
          ),
          "End of period": moment(p.endDate).format("dddd, MMMM DD, YYYY"),
          "Payroll run date": payRollRunDate.format("dddd, MMMM DD, YYYY"),
          "Pay date": payDate.format("dddd, MMMM DD, YYYY"),
          startDate: p.startDate,
          endDate: p.endDate
        };

        if (payDate.isAfter(moment()) && !selected) {
          row["_rowVariant"] = "warning";
          selected = true;
        }

        // this.dataTable.dataSet.push(row)
        this.calendar.data.push(row);
      });
    }
  },
  watch: {}
};
</script>

<style></style>
